class SetNavigationExtension extends Autodesk.Viewing.Extension {
    constructor(viewer, options) {
        super(viewer, options);
        this._group = null;
        this._button = null;
        this._icons = options.icons || [];
    }

    load() {
        const updateIconsCallback = () => {
            if (this._enabled) {
                this.updateIcons();
            }
        };
        this.viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, updateIconsCallback);
        this.viewer.addEventListener(Autodesk.Viewing.ISOLATE_EVENT, updateIconsCallback);
        this.viewer.addEventListener(Autodesk.Viewing.HIDE_EVENT, updateIconsCallback);
        this.viewer.addEventListener(Autodesk.Viewing.SHOW_EVENT, updateIconsCallback);
        return true;
    }

    unload() {
        // Clean our UI elements if we added any
        if (this._group) {
            this._group.removeControl(this._button);
            if (this._group.getNumberOfControls() === 0) {
                this.viewer.toolbar.removeControl(this._group);
            }
        }

        return true;
    }

    onToolbarCreated() {
        // Create a new toolbar group if it doesn't exist
        this._group = this.viewer.toolbar.getControl('customExtensions_Navigation');
        if (!this._group) {
            this._group = new Autodesk.Viewing.UI.ControlGroup('customExtensions_Navigation');
            this.viewer.toolbar.addControl(this._group);
        }

        // Add a new button to the toolbar group
        this._button = new Autodesk.Viewing.UI.Button('IconExtension_Navigation');
        this._button.onClick = (ev) => {
            this._enabled = !this._enabled;
            this.showIcons(this._enabled);
            this._button.setState(this._enabled ? 0 : 1);

        };
        this._button.setToolTip(this.options.button.tooltip);
        this._button.container.children[0].classList.add('fas', this.options.button.icon);
        this._group.addControl(this._button);
    }

    showIcons(show) {
        //this.viewer.impl.model.getGlobalOffset() {x: 401.5789813157292, y: 621.3871459520716, z: -15.114027688696254}
		// xtarget = xsrc + d*cos(angleRad)
		// ytarget = ysrc + d*sin(angleRad)
        //this.SetNavigation(2.096533,-2.778134,-4.369973 ,-3.257491,65.695057,65.695057);
        var strbutton="<button class='' onclick='this.SetNavigation(2.096533,-2.778134,-4.369973 ,-3.257491,65.695057,65.695057);'>unit6F2-pano1:-60deg</button><br>"
		+"<button class='' onclick='this.SetNavigation(17.621115,17.112447,-3.936249,1.037810,65.169223,65.169223);'>unit6F2-pano2</button><br>"
        +"<button class='' onclick='this.SetNavigation(15.977613,11.004554,12.839685,12.321339,64.986371,64.986371);'>unit6F2-pano3</button><br>"
        +"<button class='' onclick='this.SetNavigation(21.217177,26.19117840,40.864962,40.355730,65.222345,65.222345);'>unit6F2-pano4</button><br>"
		+"<button class='' onclick='this.SetNavigation(-26.758782,-21.845794,2.632077,3.560813,50,50);'>unit6-pano17:-40deg</button><br>"
        +"<button class='' onclick='this.SetNavigation(-25.126468,-29.004586,13.066634,16.222613,50,50);'>unit6-pano18</button><br>"
        +"<button class='' onclick='this.SetNavigation(-12.654991,-8.946856,56.638430,53.284368,50,50);'>unit6-pano20</button><br>"
        +"<button class='' onclick='this.SetNavigation(-24.928247,-23.362014,85.375223,80.626864,50,50);'>unit6-pano21</button><br>"
        +"<button class='' onclick='this.SetNavigation(-13.788131,-15.327198,109.862045,114.619279,50,50);'>unit6-pano22</button><br>" 
        +"<button class='' onclick='this.SetNavigation(-21.751559,-25.084943,109.784551,113.511285,50,50);'>unit6-pano23</button><br>"
        +"<button class='' onclick='this.SetNavigation(-4.292908,-8.759984,135.853584,133.607425,50,50);'>unit6-pano24</button><br>"
        +"<button class='' onclick='this.SetNavigation(21.139821,17.231527,146.329258,149.447789,50,50);'>unit6-pano25</button><br>"
        +"<button class='' onclick='this.SetNavigation(128.343832,132.123294,8.778690,5.505210,50,50);'>unit4-pano6: +40</button><br>"
		+"<button class='' onclick='this.SetNavigation(128.046888,131.708016,-3.105268,-6.510579,50,50);'>unit4-pano7</button><br>"
		+"<button class='' onclick='this.SetNavigation(140.804164,142.784945,3.764848,8.355764,50,50);'>unit4-pano8</button><br>"
		+"<button class='' onclick='this.SetNavigation(140.436220,136.963073,14.254813,17.851652,50,50);'>unit4-pano9</button><br>";

        //const $viewer = $('#' + this.viewer.clientContainer.id + ' div.adsk-viewing-viewer');
        //if(show){
        //$viewer.append("<div id='NavigationPanel' class='docking-panel model-structure-panel' style='resize: none; display: block; max-height: 340px; max-width: 1366px; top: 10px; left: 10px; height: calc(310px);'>"+strbutton+"</div>");
        //}else{
         //   $("#NavigationPanel").remove();
        //}
        
    }
    SetNavigation(x1,x2,y1,y2,z1,z2) {

        //viewer.navigation.setView({ x: -61, y: -26.18, z: 77.36 }, { x: -80, y: -20, z: 77.36 })
        if (x1 != undefined && x2 != undefined && y1 != undefined && y2 != undefined && z1 != undefined && z2 != undefined) {
        this.viewer.navigation.setView({ x: x1, y: y1, z: z1 }, { x: x2, y: y2, z: z2 });
        this.viewer.navigation.toPerspective();
        this.viewer.navigation.setVerticalFov(75, true);
        this.viewer.getCamera().perspectiveCamera.zoom = 2
        //viewer.navigation.setCameraUpVector (0,0,1) ;
        
        //$("#toolbar-bimWalkTool").click();
        }
    };
    getModifiedWorldBoundingBox(dbId) {
        var fragList = this.viewer.model.getFragmentList();
        const nodebBox = new THREE.Box3()
		if(this._frags!=undefined){
        // for each fragId on the list, get the bounding box
        for (const fragId of this._frags['dbId' + dbId]) {
            const fragbBox = new THREE.Box3();
            fragList.getWorldBounds(fragId, fragbBox);
            nodebBox.union(fragbBox); // create a unifed bounding box
        }}

        return nodebBox
    }

    updateIcons() {
        for (const label of $('#' + this.viewer.clientContainer.id + ' div.adsk-viewing-viewer .update')) {
            const $label = $(label);
            const id = $label.data('id');

            // get the center of the dbId (based on its fragIds bounding boxes)
            const pos = this.viewer.worldToClient(this.getModifiedWorldBoundingBox(id).center());

            // position the label center to it
            $label.css('left', Math.floor(pos.x - $label[0].offsetWidth / 2) + 'px');
            $label.css('top', Math.floor(pos.y - $label[0].offsetHeight / 2) + 'px');
            $label.css('display', this.viewer.isNodeVisible(id) ? 'block' : 'none');
        }
    }
}

Autodesk.Viewing.theExtensionManager.registerExtension('SetNavigationExtension', SetNavigationExtension);